












import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import CardChartData from './modelsCard';

@Component
export default class FormCard extends FormBase {
  data: CardChartData = {
    title: '',
    description: '',
    icon: '',
    label: '',
    valueField: '',
  };
}
